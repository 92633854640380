import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Septiembre"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/septiembre"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/septiembre",
        title: "Efemérides - Septiembre | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – SEPTIEMBRE</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de setiembre de 1898:&nbsp;Fue designado gobernador titular de la
      provincia de Salta, don Pío de Uriburu, quien se interesó ensonectar Metán
      y el río Paraná por ferrocarril.
    </p>
    <p>
      1 de setiembre de 1901: Se hizo cargo del gobierno de Salta, don Angel
      Zerda.
    </p>
    <p>
      1 de setiembre de 1904: Asumió como gobernador de Salta don David Ovejero,
      quien gobernó hasta el 20 de noviembre de 1906, fecha en que es elegido
      senador nacional.
    </p>
    <p>
      1 de setiembre de 1970: Apareció en Metán el primer número de la revista
      “Juramento”. La dirigía Miguel Lopuszynsky. Aparecieron dos números.
    </p>
    <p>
      1 de setiembre de 1987: Falleció el doctor Demetrio Jorge Herrera, quien
      realizó una destacada tarea como dirigente de entidades deportivas de
      Salta. En la década del 40 dirigía una publicación dedicada al deporte.
    </p>
    <p>
      2 de setiembre de 1773: Nació en Tucumán, José Agustín Molina. Sacerdote.
      Celebró los triunfos de 1813 de Tucumán y Salta. Conocido como el obispo
      Molina. Estudió en la Universidad de Córdoba, donde trabó amistad con su
      maestro Fray Cayetano Rodríguez. Se doctoró en teología en 1795 y al año
      siguientese ordenó sacerdote. Colaboró con El Redactor. Fue dos veces
      diputado. Compuso un poema de 56 sextinas perfectamente regulares de
      endecasílabos y heptasílabos, titulado: Al vencedor de Tucumán y Salta.
      Murió en Tucumán el 1 de octubre de 1838.
    </p>
    <p>
      2 de setiembre de 1894: Nació Juan Manuel de los Ríos, docente que fue
      miembro del Instituto San Felipe y Santiago de Estudios Históricos de
      Salta y miembro fundador del Instituto Güemesiano de Salta y de su
      Academia.
    </p>
    <p>
      2 de setiembre de 1969: Se inauguró el edificio de la Universidad Católica
      de Salta, con la presencia del Presidente de la Nación, teniente general
      Juan Carlos Onganía. Su rector era el sacerdote Jesuita George Haas. La
      Universidad había funcionado desde marzo de 1967 en las instalaciones del
      Colegio Belgrano, que los padres lateranenses habían dispuesto.
    </p>
    <p>
      3 de setiembre de 1812: Batalla de Las Piedras. El comandante Diego
      González Balcarce, del ejército patriota, derrotó a la vanguardia realista
      del ejército que mandaba el genral Pío Tristán, en Las Piedras, provincia
      de Salta.
    </p>
    <p>
      3 de setiembre de 1923: Nació en Salta, la docente e historiadora Olga
      Chiericotti. En 1940 se recibió de maestra normal. El 3 de abril de 1959
      se recibió de profesora de historia. Es la primera graduada del
      Departamento de Humanidades y la primera mujer que cursó íntegramente en
      Salta una carrera Universitaria. Dictó conferencias en Santiago del Estero
      y otras ciudades del país. Colaboró con publicaciones de la Facultad de
      Filosofía y Letras de Tucumán y ejerció la docencia en múltiples
      establecimientos educativos de Salta. Miembro del Instituto Güemesiano,
      del Instituto San Felipe y Santiago de Estudios Históricos de Salta, de la
      Junta de Historia Eclesiástica Argentina y de la Junta de Estudios
      Históricos de Tucumán.
    </p>
    <p>
      3 de setiembre de 1965: El Banco de Préstamos y Asistencia Social de
      Salta, inauguró el juego de la Tómbola.
    </p>
    <p>
      4 de setiembre de 1821: José Antonino Fernández Cornejo, gobernador de
      Salta, nombró al Coronel Mayor Agustín Dávila como Teniente Gobernador.
      Los dos funcionarios eran dirigentes de la Patria Nueva y muy opuestos al
      sistema sostenido por sus adversarios; ambos tenían evidentes propósitos
      de borrar los rastros de la administración anterior. La Patria Nueva se
      encontraba como todos los partidos largo tiempo recluido, en el
      ostracismo, con el corazón cargado de venganzas y era de temer que al
      inaugrar su paso por la vida pública, lo hiciera desbordando la ira de sus
      pasiones tanto tiempo reprimidas. Este era el panorama político a poco más
      de dos meses de la muerte de Güemes.
    </p>
    <p>
      4 de setiembre de 1987: Inauguró una muestra de sus obras la artísta Telma
      Palacios. La misma se denominó: “16 años de pintura en Salta”, ya que la
      artista cumplía 16 años de residencia en nuestra provincia, luego de venir
      de su Córdoba natal. La muestra se presentó en el MUSEO/ Provincial de
      Bellas Artes, La Florida 20 y permaneció allí hasta el 17 de setiembre.
    </p>
    <p>
      5 de setiembre de 1840: Fue designado Vicario Capitular el Sacerdote
      Antonio González de Sanmillán, cura vicario de Chicoana, introduciéndose
      una modificación con relación a las anteriores designaciones, ya que se lo
      nombraba sólo por dos años.
    </p>
    <p>
      6 de setiembre de 1811: Güemes se reunió en Tarija con Juan Martín de
      Pueyrredón. Luego hicieron la ruta de Baritú hasta Orán, de donde
      siguieron viaje hasta Campo Santo.
    </p>
    <p>
      6 de setiembre de 1930: Un golpe militar derrocó al Presidente Hipólito
      Yrigoyen. Al otro día asumió el gobierno de Salta, el Coronel Ernesto Bay,
      como delegado Nacional.
    </p>
    <p>
      6 de setiembre de 1933: Falleció en San Lorenzo, Salta, Patricio Fleming.
      Nació en Salta en 1881. Realizó los estudios primarios y secundarios en su
      ciudad natal. Estudió Derecho en Buenos Aires. Fue Ministro de Gobierno.
    </p>
    <p>
      6 de setiembre de 1969: Nació Juan Manuel Urtubey. Se graduó de abogado en
      1992. Fue asesor legal de la Federación Argentina de Trabajadores de Luz y
      Fuerza, del Sindicato Ferroviario y de Futbolistas Argentinos Agremiados.
      El gobernador de Salta, doctor Juan Carlos Romero, lo designó Secretario
      de Gobierno y luego Secretario de Prensa y Difusión de la Provincia.
    </p>
    <p>
      7 de setiembre de 1867: Nació en Cafayate, el doctor Juan B. Peñalba. Tuvo
      destacada actuación en la Política, llegando a ser Presidente del partido
      Radical en Salta. Fue gobernador interino de la provincia.
    </p>
    <p>
      7 de setiembre de 1915: Nació Pedro Recinaldo Lira, quien se ordenó de
      sacerdote el 21 de setiembre de 1938. Fue cura párroco en distintas
      parroquias, luego Vicario, Obispo Auxiliar de Salta y Obispo de San
      Francisco, Córdoba. En la actualidad, como Obispo Emérito, reside en la
      ciudad de Salta. Goza de gran reconocimiento entre los intelectuales por
      sus grandes condiciones como filósofo y literato.
    </p>
    <p>
      7 de setiembre de 1930: Finalizó el mandato del gobernador de Salta, don
      julio Cornejo, siendo sucedido por Ernesto Day.
    </p>
    <p>
      7 de setiembre de 1938: Nació en Salta, Tomás Campos. Conocido
      popularmente como Tutú Campos. Fue integrante de Las Voces del Huayra,
      cantor solista y en la actualidad integrante de Los Cantores del Alba,
      conjunto del que fue fundador. Como solista grabó con la orquesta de Waldo
      de los Ríos. Con su actividad artística viajó por Estados Unidos, Europa y
      todas las provincias argentinas. Tiene cuarenta años dedicados a la
      canción popular argentina.
    </p>
    <p>
      8 de setiembre de 1943: Se fundó el Centro Vecinal, Cultural y Deportivo
      Villa Cristina, en la ciudad de Salta.
    </p>
    <p>
      8 de setiembre de 1943: Nació en Metán, Hugo Cuellar. Músico conocido como
      el Cara I’ Mula. De familia humilde, tenía 9 hermanos. Primero se dedicó
      al fútbol donde se destacó y luego se hizo popular con la música, con su
      fuelle. Horacio Guarany le dedicó un tema en su homenaje.
    </p>
    <p>
      8 de setiembre de 1969: El Sumo Pontífice creó la Prelatura de Cafayate.
      La jurisdicción de actuación abarcaba el Departamento de Cafayate, San
      Carlos, Molinos, Tafí del Valle (Tucumán), Santa María y Antofagasta de La
      Sierra (Catamarca).
    </p>
    <p>
      9 de setiembre de 1819: El capitán Alejandro Burela, del ejército de
      Güemes, derrotó a los realistas mandados por el general Pedro Antonio
      Olañeta, en Rosario, provincia de Salta.
    </p>
    <p>
      9 de setiembre de 1829: Murió en Salta, José Gabriel Figueroa. Nació en
      Salta en 1765. Estudió en Córdoba, luego en la Universidad de Charcas,
      donde se graduó de abogado y posteriormente se doctoró en Derecho. Se
      ordenó sacerdote en 1793. De regreso a Salta desempeñó su ministerio en
      varias parroquias. Fue cura Vicario en Chicoana, después en Orán. Fue
      capellán de las fuerzas patriotas con Belgrano.
    </p>
    <p>
      9 de setiembre de 1921: Nació el doctor Hugo Saravia Cánepa. Fue dos veces
      Presidente del Círculo Médico de Salta, 1964/1965 y 1971/1972. Creador del
      Servicio de Cirugía Toráxica en el Hospital Arenales (1953). Secretario de
      COMRA (1971 A 1973). Murió el 17 de mayo de 1973.
    </p>
    <p>
      9 de setiembre de 1938: Nació en Salta, Humberto Flores. Fue miembro
      fundador de la Orquesta Municipal y de la Orquesta Estable de la
      Provincia, a la que sigue aportando con su violín. Fue Concejal de la
      Municipalidad de la ciudad de Salta.
    </p>
    <p>
      9 de setiembre de 1957: Nació en Campamento Vespucio, Alejandro Ubaldo
      Pojasi. Martillero Público Nacional e investigador de Historia. Es autor
      del libro: “Tartagal, Historia de una región”.
    </p>
    <p>
      10 de setiembre de 1873: Nació en Salta, Adolfo Güemes, nieto del héroe.
      Se graduó de médico cirujano en 1898, en Buenos Aires. Se doctoró en
      París. En 1904 regresó al país. En Salta, resultó electo Gobernador
      (ejerció desde el 1º de mayo de 1922 hasta el 1º de mayo de 1925). Tuvo a
      su cargo la inauguración de la Maternidad Luisa Bernal de Villar. Dejó a
      la Nación por legado testamentario la histórica Chacra “El Carmen de
      Güemes”, para que sea destinada a escuela agrícola. Allí funciona la
      escuela Agrícola General Güemes. Falleció en Buenos Aires el 4 de octubre
      de 1947.
    </p>
    <p>
      10 de setiembre de 1974:&nbsp;Un colectivo se desbarrancó en la Cuesta del
      Obispo. Hubo muertos y heridos. Falleció el sacerdote redentorista
      reverendo padre Andrés Ibañez, párroco de la Iglesia San Alfonso de Salta.
      También una hermana del Patronal de Seclantás.
    </p>
    <p>
      11 de setiembre de 1934: El Senado de la Nación formó la siguiente terna
      para proveer el cargo de Arzobispo de Salta: 1º)Padre Roberto José
      Tavella, Sacerdote Don Bosco; 2º)Prebístero Miguel Angel Vergara; 3º)El
      Padre Rafael Saravia.
    </p>
    <p>
      11 de setiembre de 1962:&nbsp;Nació en Orán, Vladimiro W. Becerra Araóz.
      Ganó el 1er. premio de un concurso literario en Orán. Figura en una
      antología de Orán.
    </p>
    <p>
      12 de setiembre de 1909: Murió en Salta, José Antonio Chavarría, nacido en
      1838. Plantó los primeros viñedos en Cafayate. Levantó el 1º
      establecimiento vinícola en Salta, que denominó Bodega “La Rosa”.
    </p>
    <p>
      12 de setiembre de 1940: Nació en Salta, Alberto Diez Gómez. Poeta que
      logró el primer Premio de la Dirección Provincial de Cultura por su libro
      “Biberones rotos” en 1974 y el segundo premio Emilio P. Corbiere otorgado
      por la Sociedad Argentina de Escritores Nacional, en 1976. Publicó poesías
      y notas culturales en periódicos y revistas de Salta, Tucumán, Córdoba y
      Buenos Aires.
    </p>
    <p>
      12 de setiembre de 1945: Nació en Capital Federal, Cristina L. de Nayar.
      Trabajó en la Dirección de Cultura de la Municipalidad de Orán en la
      provincia de Salta. Escribe poemas y figura en una antología.
    </p>
    <p>
      13 de setiembre de 1692: Unos tremendos sacudones de tierra sembraron el
      pánico entre los vecinos de Salta. El violento terremoto destruyó
      totalmente a Esteco El Nuevo, que había sido levantado por Juan Gregorio
      Bazán. En el departamento de Metán, Salta, cerca de El Galpón.&nbsp;
      <br />
      13 de setiembre de 1878: Fue consagrada La Catedral de Salta, sin que
      estuviesen construidas las torres, el frente y el atrio, lo cual recién se
      llevó a cabo en el año 1882. Gobernaba la diócesis Fray Buenaventura Rizo
      Patrón y era Obispo Auxiliar don Miguel Moisés Aráoz.
    </p>
    <p>
      13 de setiembre de 1902: Tuvo lugar la coronación de la imágenes del Señor
      del Milagro, en solemne ceremonia presidida por el Obispo de Salta
      monseñor Matías Linares Sanzetenea, y representando al Presidente de la
      República, el gobernador don Angel Zerda.
    </p>
    <p>
      14 de setiembre de 1850: Nació en Molinos, Salta, Indalecio Gómez. Se
      recibió de abogado en Chuquisaca y en Buenos Aires revalidó su título, en
      1876. Fue docente en el Colegio Nacional y en el Seminario Conciliar. Fue
      intendente de Salta, diputado provincial, diputado nacional por dos
      períodos. Fue el primer Decano de la Facultad de Filosofía y Letras de la
      Universidad de Buenos Aires. Fue ministro plenipotenciario ante los
      gobiernos de Alemania, Austria, Hungría y Rusia. Fue ministro del interior
      del presidente Roque Sáenz Peña. Elaboró la conocida Ley Sáenz Peña.
      Retirado de la vida pública, se instaló en su campo “Pampa Grande” en
      Guachipas, Salta, que perteneciera al general Arenales. Allí invitó a su
      amigo Ambrosetti. Su fallecimiento ocurrió en Buenos Aires el 17 de agosto
      de 1920.
    </p>
    <p>
      15 de setiembre de 1692: La imagen de Cristo, que fuera recibida el 15 de
      setiembre de 1592 y olvidada durante una centuria, apareció nuevamente en
      las calles de Salta, el mismo día de su llegada el 15 de setiembre pero
      cien años después, 1692. A partir de entonces Salta celebre los días 13,
      14 y 15 de Setiembre, los solemnes cultos del Señor y la Virgen del
      Milagro.
    </p>
    <p>
      15 de setiembre de 1815: Murió en Rosario de Lerma (Salta), Bernarda Díaz
      de Zambrano de Saravia y Jáuregui. Colaboró con sus bienes personales para
      el sostén del ejército Auxiliar del Norte. Abrió las puertas de su casa a
      numerosos patriotas que llegaron a Salta como: French, Dorrego, La
      Quintana, etc. Socorrió a los soldados curando sus heridas, aportando
      consuelo y reconfortando a los soldados enemigos prisioneros. Restauró
      templos y refaccionó hospitales.
    </p>
    <p>
      15 de setiembre de 1816: Nació en Salta, en la quinta El Carmen de Güemes
      (actual escuela granja General Martín Miguel de Güemes), el hijo
      primogénito del héroe, Martín del Milagro Güemes y Puch. A la muerte de su
      padre no había cumplido cinco años. Fue elegido a la Sala de
      Representantes, de la que fue su presidente. Asumió interinamente el
      gobierno de la provincia en octubre de 1856, para luego entregarlo a su
      tío materno Dionisio Puch, ejerciendo desde el 6 de junio de 1857 hasta el
      1º de junio de 1859.
    </p>
    <p>
      15 de setiembre de 1897: Nació en Salta, José María del Milagro Gallo
      Mendoza. Estudió en la Escuela Normal de Salta, donde se recibió de
      maestro. Se radicó en Chivilcoy, provincia de Buenos Aires, donde ejerció
      la docencia hasta su jubilación, treinta años después. Regresó a Salta
      donde se dedicó al periodismo y a las letras. El periodismo lo realizó en
      El Cívico, El Intransigente y en Nueva Epoca. En las letras publicó los
      siguientes libros: La Eucaristía del Arte, La Casa 17, El viejo cofre, Los
      hijos de otros (teatro), Color de desvelo, Palabras de silencio, Voces en
      soledad, y muchas colaboraciones en diarios y revistas. Fue Diputado
      Provincial. Murió el 1 de abril de 1964.
    </p>
    <p>
      16 de setiembre de 1924: Se habilitó el tramo Embarcación – Manuela
      Pedraza (hoy Tartagal), de la línea ferroviaria.
    </p>
    <p>
      16 de setiembre de 1942: Se inauguró el Primer Congreso de la Cultura
      Hispanoamericana, en el cine-teatro Güemes, ubicado en la calle Zuviría
      frente a la plaza 9 de julio de la ciudad de Salta. Asistió el Presidente
      de la Nación.
    </p>
    <p>
      16 de setiembre de 1945: Nació en Salta Francisco Ruiz. Egresado de la
      Escuela Provincial de Bellas Artes de su ciudad natal y luego de la
      Escuela Superior de Bellas Artes de la Nación, “Ernesto de la Cárcova”. En
      1974, la UNESCO le otorgó una beca para estudiar restauración de pintura
      colonial en México, en el Convento de Churubusco. Reside en Bogotá,
      Colombia, desde 1976. Vive y trabaja alternativamente entre New York,
      México y Bogotá.
    </p>
    <p>
      17 de setiembre de 1789: Nació en Salta, Nicolás Medina. Integró la
      infantería en el Ejército de los Andes. Cruzó la frontera con el cuerpo
      que comandaba el general Las Heras, zarpó al Perú con el ejército
      expedicionario. Participó en la toma de Lima. Emprendió una campaña contra
      los indios del sur de la provincia de Buenos Aires y en un encuentro
      contra los aborígenes murió en “Las Viscacheras”, el 28 de marzo de 1929.
    </p>
    <p>
      17 de setiembre de 1827: Fue ejecutado en Salta, por orden del gobernador
      José Ignacio Gorriti y a raíz de los disturbios políticos en esa
      provincia, el coronel Domingo López Matute.
    </p>
    <p>
      17 de setiembre de 1878: Nació en Salta, Antonio Ortelli. Médico-cirujano
      del V de Caballería. Ministro de gobierno. Senador provincial. Falleció el
      21 de setiembre de 1947.
    </p>
    <p>
      18 de setiembre de 1815: El Cabildo de Jujuy, reconoció a Güemes como
      gobernador, llevada a cabo en Salta.
    </p>
    <p>
      18 de setiembre de 1915:&nbsp;Nació en Salta, Humberto Bisceglia. Se
      recibió de médico en 1943, se esperacializó en Oftalmología. Se desempeñó
      en hospitales de Buenos Aires. Fue jefe de Neuro Oftalmología Cátedra de
      Neurocirugía de la Facultad de Mécidina de la Universidad Nacional de la
      Plata. Fue secretario regional de la Sociedad Oftalmológica Sudamericana.
      Docente de Oftalmología en la Facultad de Medicina de Buenos Aires.
      Miembro de la Sociedad Argentina de Oftalmología y de muchas otras
      entidades. Reside en Capital Federal.
    </p>
    <p>
      18 de setiembre de 1935: Nació en Tartagal. Tomás Valdiviezo. Profesor de
      Arte. Estudió en la Escuela de Bellas Artes. Participó en muestras
      individuales y colectivas. Es el vicerrector del Centro Polivalente de
      Arte.
    </p>
    <p>
      19 de setiembre de 1815: El Cabildo de Jujuy, después de 4 meses reconoció
      a Güemes como gobernador de la Intendencia de Salta.
    </p>
    <p>
      19 de setiembre de 1931: Nació en Salta, Normando Arciénaga. Realizó sus
      estudios primarios en las escuelas Mariano Cabezón y Julio Argentino Roca,
      los estudios secundarios en la Escuela Fábrica Nº 32 (actual ENET Nº 3) y
      obtuvo el título técnico de fábrica. Luego realizó estudios superiores en
      Santa Fe y en la Universidad Obrera de Córdoba. Fue docente en varias
      escuelas técnicas de la ciudad de Salta. (ENET Nº 1, Nº 3 Y Nº 4). Fue
      Secretario General del Sindicato de Luz y Fuerza de Salta, y Secretario de
      la confederación General del Trabajo Regional de Salta. Fue elector para
      Presidente y Vicepresidente de la Nación en 1983. Miembro del Consejo
      Provincial del Partido Justicialista. Fue elegido diputado nacional para
      el período 1987 – 1991, donde fue Secretario de la Comisión de Energía y
      Combustible, Vocal de la Comisión de Ciencia y Técnica, Vicepresidencia de
      la Comisión de Peticiones, Poderes y Reglamento y representante de la
      Cámara de Diputados de la Nación ante el Consejo Federal de Energía.
      Publicó un informe sobre repositor Nuclear de Gastre en el año 1989. Actuó
      en el fútbol en el Club Pellegrini entre 1947 y 1954.
    </p>
    <p>
      19 de setiembre de 1994: Falleció en Salta, a los 82 años, Alberto Ignacio
      Saravia. Fue pionero del turf salteño. Conocido en el ambiente turfístico
      como Don Alberto. Fue largador oficial del hipódromo de Limache
      (estarter). Se jubiló como largador.
    </p>
    <p>
      20 de setiembre de 1801: Nació en Salta, Eustoquio Frías. Ingresó al
      ejército patriota el 11 de marzo de 1816, cuando aún no había cumplido 15
      años, comenzó como soldado del Regimiento de Granaderos a Caballo. Luchó
      en Chile, Perú, en la gesta Sanmartiniana. Bolivar le otorgó medalla de
      oro. En 1828 regresó a Buenos Aires. Fue a la guerra con Brasil. Luchó
      junto a Lavalle en la lucha entre federales y unitarios. Participó de la
      guerra con el Paraguay. En 1882 le fue entregada la más alta graduación
      militar: Teniente General. El gobierno de Roca le encomendó la guarnición
      militar de la Capital Federal. Renunció un año antes de su muerte, cuando
      tenía 90 años, luego de prestar 75 años al servicio del Ejército
      Argentino. De soldado llegó al máximo cargo de su tiempo. Falleció en
      Buenos Aires el 16 de marzo de 1891.
    </p>
    <p>
      20 de setiembre de 1852: Se reunió el Congreso Constituyente, presidido
      por el representante de Salta, don Facundo de Zuviría.
    </p>
    <p>
      20 de setiembre de 1932: Nació en Buenos Aires, Ricardo Castro, quien fue
      director del Cine Arte y el primer presidente del Abril Cultural Salteño.
      Escribe notas sobre cine en diario El Tribuno. Conductor por más de 20
      años del programa radial, por Radio Salta, “Qué veremos esta noche? Da
      conferencias y charlas sobre cine.
    </p>
    <p>
      20 de setiembre de 1934: El Papa Pío XI nombró como primer arzobispo de
      Salta, a monseñor Doctor Roberto José Tavella, por renuncia del obispo
      titular Julio Campero y Aráoz.
    </p>
    <p>
      20 de setiembre de 1988: Falleció en Tarija, Bolivia, el doctor Numa
      Romero del Carpio a los 78 años de edad. Fue un ferviente güemesiano.
      Profesor de filosofía. Colaboró con diarios de La Paz, Tarija y Salta. Fue
      académico correspondiente en Tarija del Instituto Güemesiano de Salta.
    </p>
    <p>
      21 de setiembre de 1896: Falleció Justo Aguilar, nacido en Salta en 1844.
      Ingresó a la carrera de las armas, alistándose en las fuerzs salteñas que
      marcharon a la guerra del Paraguay. Hizo frente a Felipe Varela, después
      de cuya campaña fue promovido al grado de Teniente Coronel.
    </p>
    <p>
      22 de setiembre de 1821: El coronel Pablo Latorre con un movimiento
      militar depuso al gobernador de Salta, coronel Juan Antonio Fernández
      Cornejo.
    </p>
    <p>
      22 de setiembre de 1912: Se realizaron comicios en la provincia de Salta,
      triunfaron el doctor Robustiano Patrón Costas.
    </p>
    <p>
      22 de setiembre de 1923: Nació en Salta, el poeta José Ríos. Es autor de
      libros de poemas y de muchas canciones interpretadas por cantores
      argentinos. Entre sus libros, destacamos los siguientes: “Unos cuantos
      versos”, “Tiempo de Felipe Varela”, “Coplas de Carnaval”, “Los días
      ausentes”, “Poemas silenciosos”, “Cafayate y otros poemas”, “Habitantes
      del baldío”, “El caracol dorado”, “Atardeceres”, “Letras con música”,
      “Ocaso” y varias plaquetas.
    </p>
    <p>
      22 de setiembre de 1935:&nbsp;Nació en Tartagal, provincia de Salta, Mario
      A. Raskovsky. Realizó sus estudios secundarios en la Escuela de Minas de
      Jujuy. En Salta, estudió en la vieja Facultad de Ciencias Naturales,
      dependiente entonces de la Universidad Nacional de Tucumán. Fue miembro de
      la Comisión Nacional de Energía Atómica. Fue Director de Fertinoa,
      Delegado del Plan Alconafta, Presidente de la Empresa del Estado La
      Casualidad S.A. y finalmente Secretario de Estado de Minería y Recursos
      Energéticos. Fue Profesor de Geología y Económica y de Yacimientos Mineros
      en la Universidad Nacional de Salta.
    </p>
    <p>
      22 de setiembre de 1954: Nació en Salta, Ricardo Narciso Alonso. Geólogo
      que se doctoró en Ciencias Geológicas. Es un reconocido profesional por
      universidades argentinas y extranjeras. Colabora en diario El Tribuno y
      enn publicaciones especializadas. Autor de una importante bibliografía.
      Conocedor como pocos de la cordillera de Los Andes, a consecuencia de
      profundas investigaciones.
    </p>
    <p>
      23 de setiembre de 1657: El general Alonso de Mercado y Villacorta, en San
      Bernardo (Salta), derrotó al impositor Pedro Bohorquez, español, prófugo
      de Chile, que se decía nieto de los últimos incas y que había sublevado a
      los infígenas de los Valles Calchaquíes.
    </p>
    <p>
      23 de setiembre de 1658: El falso inca Bohorquez al frente de 1200
      Calchaquíes, atacó al gobernador, que se había refugiado en el antiguo
      Fuerte de San Bernardo, a unas tres leguas al sur de Salta, sufriendo los
      Calchaquiés una grave derrota.
    </p>
    <p>
      23 de setiembre de 1946: Se fundó en la ciudad de Salta la Agrupación
      Tradicionalista “Gauchos de Güemes”, siendo su primer presidente Dn. Oscar
      Socia Arias y Dn. Isidro Gareca secretario. Esta entidad trabaja
      celosamente por los valores tradicionales de la provincia y por la cultura
      de la raza criolla.
    </p>
    <p>
      23 de setiembre de 1949: Se inauguró el edificio de la Escuela Normal de
      Rosario de la Frontera, con la presencia del gobernador Emilio Espelta y
      el arzobispo Roberto José Tavella.
    </p>
    <p>
      23 de setiembre de 1957: Nació en Salta, José Antonio Vargas. Poeta que
      publicó un libro adolescente.
    </p>
    <p>
      24 de setiembre de 1816: Gran triunfo de Güemes sobre fuerzas realistas en
      el combate de Santa Victoria, al norte de la provincia de Salta.
    </p>
    <p>
      24 de setiembre de 1840: En la ciudad de San Miguel de Tucumán, se firmó
      el acuerdo de “Liga del Norte contra Rosas”, que incluye a las provincias
      de Jujuy, Salta, Catamarca y La Rioja.
    </p>
    <p>
      24 de setiembre de 1965: Se reconoció oficialmente a nivel nacional, a la
      Escuela de Enfermería “Hospital Vespucio” de YPF, que funcionó en Vespucio
      y fue reconocida precariamente en 1947.
    </p>
    <p>
      25 de setiembre de 1988: Se iniciaron “Aportes Culturales de Escritores
      Salteños al Congreso de Cardiología”. En esta jornada el escritor Fernando
      R. Figueroa se refirió a “Mitos y Leyendas Regionales” y el escritor
      Eduardo Ceballos lo hizo sobre “Literatura del NOA y de Salta”. Participó
      también en otras jornadas, el escritor César Antonio Alurralde.
    </p>
    <p>
      26 de setiembre de 1935: Por ley Nº 1.523, se instaló una estación
      radiodifusora de propiedad fiscal en la ciudad de Salta.
    </p>
    <p>
      26 de setiembre de 1971: Se fundó el Club Amigos del Tango en la Ciudad de
      Salta, en una lluviosa noche en los Salones de la Sociedad italiana. El
      primer presidente fue don Eduardo Farah.
    </p>
    <p>
      26 de setiembre de 1994: Se presentó en el MUSEO/ Casa de Hernández de la
      ciudad de Salta, el periódico cultural Cobas, que editó la Comisión
      Bicameral Examinadora de Obras de Autores Salteños. Salieron 2 números.
    </p>
    <p>
      27 de setiembre de 1817: Güemes comunicó a Belgrano que ha impuesto una
      contribución general, con ausencia del Cabildo, para sostener a los que
      defienden a la Patria y que la intendencia de Salta “a fuerza de
      sacrificios, ha logrado que los demás pueblos hermanos conserven su
      seguridad ysu sosiego”, pero que ya se halla en la impotencia y requiere
      que todos contribuyan con sus auxilios. Belgrano transmitió el pedido al
      Director Supremo Pueyrredón, quien prometió resolver favorablemente lo
      requerido pero los auxilios no llegaron.
    </p>
    <p>
      27 de setiembre de 1826: El general José María Córdoba, del ejército
      boliviano, derrotó a las tropas argentinas y ocupó el pueblo que el
      general Antonio Alvarez de Arenales había agregado a la provincia de Salta
      (Argentina), por el voto de sus habitantes. El pueblo en cuestión es Mojo,
      en la provincia de Tarija, Bolivia.
    </p>
    <p>
      27 de setiembre de 1951: Nació en Rosario de Santa fe, Martín Miguel
      Güemes Arruabarrena (h). Fue fundador de La Orden del Poncho y del Centro
      de Estudios Dr. Indalecio Gómez; Director de la Casa de Salta en Capital
      Federal. Articulista y conferencista en temas de divulgación histórica.
      Conductor de programas radiales por FM ABC.
    </p>
    <p>
      27 de setiembre de 1995: Falleció a los 63 años la profesora de Filosofía
      y Pedagogía, Nelly Cortés de Ubiergo. Poetisa autora del libro de poemas
      “Mínimo Equipaje”. Es autora del Himno al Bachillerato Humanista Moderno.
      Ganadora de Juegos Florales de la Universidad Católica de Salta y de un
      Segundo Premio en un concurso organizado por diario El Intransigente.
      También recibió premios de la Dirección Provincial de Cultura.
    </p>
    <p>
      28 de setiembre de 1812: Sucedió la revolución independiente que rinde a
      las fuerzas realistas y designa al general Juan Antonio Alvarez de
      Arenales, en Salta.
    </p>
    <p>
      28 de setiembre de 1831: Fue asesinado el capitán Juan de Dios Melián,
      mientras conducía comunicaciones de acuerdo al armisticio del 9 de
      setiembre de 1831, entre el general Alvarado, gobernador de Salta y el
      coronel Pablo Latorre, en representación del brigadier Estanislao López,
      jefe del ejército de la Confederación. Preso por el general Gregorio Aráoz
      de Lamadrid, fue barbaramente asesinado por los coroneles Santiago
      Albarracín, Lorenzo Barcala y mariano Acha.
    </p>
    <p>
      28 de setiembre de 1984: Se constituyó el Sindicato del Petróleo y Gas
      Privado de Salta y Jujuy, siendo su primer secretario General el Señor
      Roberto Arias. Su primera sede estaba ubicada en la calle Belgrano 79, de
      la ciudad de Tartagal.
    </p>
    <p>
      29 de setiembre de 1905: La ley Nº 4.693 autorizaba la ejecución de
      estudios de una vía férrea que, “partiendo de un punto conveniente del
      Valle de Lerma, termine en Huaytiquina o en otro punto próximo de la
      frontera chilena”. (cuando las vías ya estaban en la estación Salta).
    </p>
    <p>
      29 de setiembre de 1917: Nació en Salta, el doctor Gustavo Leguizamón.
      Obtuvo importantes premios y reconocimientos, en el Festival
      Latinoamericano, SADAIC, Cosquín, el Gran Premio Nacional de Música. Es
      reconocido en el mundo. Su repertorio es cantado por la mayoría de los
      intérpretes argentinos. Entre sus temas más conocidos: Zamba de
      Balderrama, Zamba del pañuelo, la Pomeña, Chacarera del diablo,
      Carnavalito del duende, Zamba de Anta, Maturana y muchos otros. Por
      iniciativa del Intendente de Cafayate, Jesús Ricardo Strisich, la XX
      Edición de la Serenata a Cafayate, en el año 1994, se dedicó en homenaje
      al Cuchi Leguizamón.
    </p>
    <p>
      30 de setiembre de 1582: Reunidos en Cabildo abierto, todos los nombres de
      los Santos del Calendario se echaron en un vaso. Una niña llamada
      Petronilla, sacó el nombre del Bienaventurado San Bernardo. Tomaron,
      entonces a San Bernardo por su patrón y abogado de Salta.
    </p>
    <p>
      30 de setiembre de 1812: El capitán Cornelio Zelaya del ejército patriota,
      al mando del general Belgrano, derrotó a una partida realista mandada por
      el alférez José Domingo Vidart, en Las Piedras, provincia de Tucumán o
      Salta.
    </p>
    <p>
      30 de setiembre de 1824: Apareció en la ciudad de Salta, el primer
      periódico denominado “Revista Mensual”, que fue redactada por José I. de
      Arenales, la que dejó de aparecer el 5 de mayo de 1825.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
